<template>
  <div class="max-w-[450px]">
    <p v-if="!isUserLoggedIn" class="font-sans text-sm font-medium tracking-tight text-black">
      {{ t("pages.sell-a-profile.enter-email", { name: bundle.seller_info.user_name }) }}
    </p>

    <div class="full-h col flex max-w-[376px] flex-col">
      <form @submit.prevent="submitEmail">
        <div v-if="!isUserLoggedIn" class="mt-3">
          <IAIInputText
            id="email"
            v-model="email"
            :placeholder="t('pages.auth.enter-email.email')"
            :rules="rules.email"
            autocomplete="email"
          />
        </div>

        <div class="buttons-wrapper mt-4 flex-row">
          <IAIButton
            class="w-full"
            :disabled="formCurrentlyInvalid"
            :label="
              !bundle.should_show_preview
                ? t('pages.sell-a-profile.next.mobile')
                : t('pages.sell-a-profile.next.desktop')
            "
            variant="primary"
            @click="submitEmail"
          />

          <div class="buy-now-wrapper" @click="onBuyProfileClicked">
            <IAIButton
              class="font-medium !text-theme-1"
              :label="t('pages.sell-a-profile.just-want-to-buy')"
              variant="link-underlined"
            />

            <img :src="ForwardArrow.src" class="text-theme-1" />
          </div>
        </div>
      </form>
    </div>
  </div>

  <IAIModal v-model="isShowAuthModal" max-width="480px">
    <div class="flex justify-center">
      <img :src="ImagenLogo.src" class="w-16" alt="Imagen" />
    </div>

    <div class="card-shadow mx-auto mt-5 min-h-[100%] max-w-[356px]" :style="`min-height: ${AUTH_FLOW_HEIGHT}`">
      <Suspense>
        <AuthFlow
          :style="`min-height: ${AUTH_FLOW_HEIGHT}`"
          :user-flow="{
            name: 'purchase_profile',
            data: {
              bundle_id: props.bundle.id,
            },
          }"
          :email="email"
          :back-button="false"
          @loginSuccess="onLoginSuccess"
        />
      </Suspense>
    </div>
  </IAIModal>
</template>

<script setup lang="ts">
import { t } from "@/locales/vue";
import { ref } from "vue";

import { IAIButton, IAIInputText, IAIUseForm, IAIModal } from "@/core-ui-vue";
import { universalStorage } from "@/utils/universal-storage";
import { funnelService } from "@/services/funnelService";
import ForwardArrow from "../../assets/ForwardArrow.svg";

import rules from "@/features/auth/common/rules";
import AuthFlow from "@/features/auth/components/AuthFlow.vue";
import ImagenLogo from "@/features/auth/assets/imagen-logo-pink.svg";
import { IBundleDetailesResponse } from "../../services/api";

const AUTH_FLOW_HEIGHT = "26rem";

const isShowAuthModal = ref(false);
const email = ref("");
const isUserLoggedIn = !!universalStorage.get("user_id");
const isMobile = universalStorage.get("mobile_or_desktop") === "mobile";

const props = defineProps<{
  bundle: IBundleDetailesResponse;
  bundleId: string;
}>();

const { formRef, currentlyInvalid: formCurrentlyInvalid } = IAIUseForm({});

const submitEmail = async () => {
  const { valid } = await formRef.validate();
  if (!valid) return;

  funnelService.trackEvent("seller_flow_next_button_clicked", {
    bundle_id: props.bundle.id,
    seller_name: props.bundle.seller_info.user_name,
    seller_source: props.bundle.seller_info.source,
  });

  if (universalStorage.get("user_id")) {
    onLoginSuccess();
  } else {
    isShowAuthModal.value = true;
  }
};

const onBuyProfileClicked = () => {
  funnelService.trackEvent("seller_flow_direct_purchase_clicked", {
    bundle_name: props.bundle.name,
    seller_name: props.bundle.seller_info.user_name,
    seller_source: props.bundle.seller_info.source,
  });
  window.location.assign(`/bundle/purchase/${props.bundleId}`);
};

function onLoginSuccess() {
  if (isMobile) {
    window.location.assign(`/bundle/switch-to-desktop/?bundle=${props.bundleId}&send-email=true`);
  } else {
    if (props.bundle.should_show_preview) {
      window.location.assign(`/bundle/preview/${props.bundleId}`);
    } else {
      window.location.assign(`/bundle/purchase/${props.bundleId}`);
    }
  }
}
</script>

<style scoped lang="scss">
.buy-now-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  gap: 4px;
  cursor: pointer;
}
.buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
